<template>
  <div>
    <a-card title="生产报表">
      <a-row gutter="24">
        <a-col :span="10">
          <a-form-model-item :wrapper-col="{ span: 24 }">
            <a-radio-group v-model="radioValue" button-style="solid" @change="changeRadio" style="width: 100%;">
              <a-radio-button value="0" style="width: 25%;">今日</a-radio-button>
              <a-radio-button value="1" style="width: 25%;">昨天</a-radio-button>
              <a-radio-button value="-6" style="width: 25%;">近7天</a-radio-button>
              <a-radio-button value="-29" style="width: 25%;">近30天</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="changeDate" style="width: 100%;" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="状态" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-select v-model="searchForm.status" allowClear style="width: 100%;" @change="changeCategory">
              <a-select-option v-for="item in statusItems" :key="item.id" :value="item.code">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-descriptions :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }" style="text-align: center;">
        <a-descriptions-item label="生产次数"><span class="total">{{ summary.productionCount }}</span></a-descriptions-item>
        <a-descriptions-item label="产品总数"><span class="total">{{ summary.totalUnits }}</span></a-descriptions-item>
        <a-descriptions-item label="销售总额"><span class="total">{{ summary.totalSales.toFixed(2)
            }}元</span></a-descriptions-item>
      </a-descriptions>

      <a-descriptions :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }" style="text-align: center;">
        <a-descriptions-item label="物料成本"><span class="total">{{ summary.totalMaterialCost.toFixed(2)
            }}元</span></a-descriptions-item>
        <a-descriptions-item label="人工成本"><span class="total">{{ summary.totalLaborCost.toFixed(2)
            }}元</span></a-descriptions-item>
        <a-descriptions-item label="物流成本"><span class="total">{{ summary.totalLogisticsCost.toFixed(2)
            }}元</span></a-descriptions-item>
        <a-descriptions-item label="其他成本"><span class="total">{{ summary.totalOtherCost.toFixed(2)
            }}元</span></a-descriptions-item>
      </a-descriptions>

      <a-descriptions :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }" style="text-align: center;">
        <a-descriptions-item label="总成本"><span class="total">{{ summary.totalCost.toFixed(2)
            }}元</span></a-descriptions-item>
        <a-descriptions-item label="总利润"><span class="total">{{ summary.totalProfit.toFixed(2) }}
            元</span></a-descriptions-item>
        <a-descriptions-item label="利润率"><span class="total">{{ summary.profitMargin }}</span></a-descriptions-item>
      </a-descriptions>

      <div>
        <!-- <flow-pane v-show="currentTab === 'flow'" :items="currentPageItems" :loading="loading" :pagination="pagination"
            @tableChange="tableChange" :statusItems="statusItems" /> -->

        <a-table :columns="columns" :data-source="currentPageItems" size="small" :pagination="pagination"
          @change="tableChange" :loading="loading">
        </a-table>

      </div>
      <div style="text-align: center; margin-top: 12px;">
        <a-spin :spinning="loading && searchForm.page > 1" />
      </div>
    </a-card>
  </div>
</template>

<script>
import { productionReportDetialsList } from '@/api/report'
import NP from 'number-precision'
import moment from 'moment'

export default {
  name: 'ProductReport',
  components: {
    // FlowPane: () => import('./flowPane'),
    // GoodsPane: () => import('./goodsPane'),
  },
  data() {
    return {
      NP,
      statusItems: [
        // {
        //   id: 1,
        //   code: 'Analysis',
        //   name: '分析',
        // }, {
        //   id: 2,
        //   code: 'SamplePurchase',
        //   name: '样品采购',
        // }, {
        //   id: 3,
        //   code: 'Purchase',
        //   name: '物料采购',
        // }, 
        // {
        //   id: 4,
        //   code: 'InProduction',
        //   name: '生产中'
        // }, 
        {
          id: 5,
          code: 'Complete',
          name: '生产完成'
        }, {
          id: 6,
          code: 'Shipped',
          name: '已发货'
        }, 
        // {
        //   id: 7,
        //   code: 'Cancel',
        //   name: '取消'
        // }
      ],
      currentTab: 'flow',
      radioValue: '0',
      summary: {
        productionCount: 0,
        totalUnits: 0,
        totalSales: 0,
        totalMaterialCost: 0,
        totalLaborCost: 0,
        totalLogisticsCost: 0,
        totalOtherCost: 0,
        totalCost: 0,
        totalProfit: 0,
        profitMargin: 0
      },
      columns: [
          {
            title: '生产单号',
            dataIndex: 'number',
            key: 'number',
            sorter: true,
          },
          {
            title: '下单用户',
            dataIndex: 'bm_user_name',
            key: 'bm_user_name',
          },
          {
            title: '状态',
            dataIndex: 'statues',
            key: 'statues',
            customRender: (text) => {
            const statusItem = this.statusItems.find(status => status.code === text);
            return statusItem ? statusItem.name : '未知状态';
          }
          },
          {
            title: '产品名称',
            dataIndex: 'goods_name',
            key: 'goods_name',
          },
          {
            title: '计划生产',
            dataIndex: 'quantity',
            key: 'quantity',
          },
          {
            title: '实际生产',
            dataIndex: 'use_quantity',
            key: 'use_quantity',
            customRender: (value, item) => {
              if((value!=0)&&(value!=item.quantity)){
                return value
              }else{
                return null
              }
            
          }
          },
          {
            title: '销售单价(元)',
            dataIndex: 'goods_sale_price',
            key: 'goods_sale_price',
            sorter: true,
          },
          {
            title: '销售总价(元)',
            dataIndex: 'sales',
            key: 'sales',
            sorter: true,
          },
          {
            title: '物料成本',
            dataIndex: 'materials_cost',
            key: 'materials_cost',
            sorter: true,
          },
          {
            title: '人工成本',
            dataIndex: 'laborCost',
            key: 'laborCost',
            sorter: true,
          },
          {
            title: '物流成本',
            dataIndex: 'express_cost',
            key: 'express_cost',
          },
          {
            title: '其他成本',
            dataIndex: 'other_cost',
            key: 'other_cost',
          },
          {
            title: '利润',
            dataIndex: 'profit',
            key: 'profit',
            sorter: true,
          },
          {
            title: '利润率',
            dataIndex: 'profitMargin',
            key: 'profitMargin',
            sorter: true,
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            sorter: true,
          },{
            title: '状态时间',
            dataIndex: 'status_time',
            key: 'status_time',
            sorter: true,
          },
        ],
      searchForm: {
        dateRange: [moment().startOf('day'), moment().startOf('day')],
        statues: null,
        page: 1,
        page_size: 16
      },
      currentPageItems: [],  // 当前页的数据
      loading: true,
      // categoryItems: [],
      total: {
        quantity: 0,
        cost: 0,
        amount: 0,
      },
      items: [],
      pagination: { current: 1, total: 0, pageSize: 16 },
    };
  },
  // computed: {

  // },
  methods: {
    initailize() {
      if (this.$route.query.type) {
        this.currentTab = this.$route.query.type;
      } else {
        this.$router.replace({ query: { type: this.currentTab } })
      }
      this.list();
      // goodsClassificationOption({ page_size: 99999 }).then(resp => {
      //   this.categoryItems = resp.results;
      // });
    },
    resetSummary() {
      // 重置 summary 对象的所有属性为 0
      this.summary = {
        productionCount: 0,
        totalUnits: 0,
        totalSales: 0,
        totalMaterialCost: 0,
        totalLaborCost: 0,
        totalLogisticsCost: 0,
        totalOtherCost: 0,
        totalCost: 0,
        totalProfit: 0,
        profitMargin: 0
      };
    },
    calculateSummary() {

      this.items.forEach(item => {
        item.sales = item.goods_sale_price ? item.goods_sale_price * item.use_quantity : 0;
        // 计算总成本，包括材料成本、人工成本（如果有）、物流和其他费用
        let rawMaterialCost = item.material_cost ? item.material_cost * item.use_quantity : 0;
        item.materials_cost = parseFloat(rawMaterialCost.toFixed(2));
        // console.log('item.materials_cost',item.materials_cost)
        item.laborCost = (parseFloat(item.worker_day) || 0) * 200; // 假设每工日成本为200
        let logisticsCost = parseFloat(item.express_cost || 0);
        let otherCost = parseFloat(item.package_cost || 0) + parseFloat(item.other_amount || 0);
        let totalCost = item.materials_cost + item.laborCost + logisticsCost + otherCost;
        item.totalCost = parseFloat(totalCost.toFixed(2));

        // 计算利润
        item.profit = parseFloat(item.sales - item.totalCost).toFixed(2)
        // 计算利润率，如果销售额为0，则利润率也为0
        item.profitMargin = item.sales ? (item.profit / item.sales * 100).toFixed(2) + '%' : '0%';
      }),
        this.summary = this.items.reduce((acc, item) => {
          // if (item.statues === "Shipped") {
          acc.productionCount++;
          acc.totalUnits += item.use_quantity;
          acc.totalSales += item.goods_sale_price * item.use_quantity;
          acc.totalMaterialCost += item.materials_cost;
          acc.totalLaborCost += item.laborCost;

          if (item.express_cost) {
            acc.totalLogisticsCost += parseFloat(item.express_cost);
          }
          if (item.package_cost) {
            acc.totalOtherCost += parseFloat(item.package_cost);
          }
          if (item.other_amount) {
            acc.totalOtherCost += parseFloat(item.other_amount);
          }
          // }
          return acc;
        }, { ...this.summary });

      this.summary.totalCost = this.summary.totalMaterialCost + this.summary.totalLaborCost + this.summary.totalLogisticsCost + this.summary.totalOtherCost;
      this.summary.totalProfit = this.summary.totalSales - this.summary.totalCost;
      this.summary.profitMargin =this.summary.totalSales? ((this.summary.totalProfit / this.summary.totalSales)  * 100).toFixed(2)+ '%':'0%';
    },

    list() {
      let form = {
        status_date_after: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[0].format('YYYY-MM-DD') : null,
        status_date_before: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[1].format('YYYY-MM-DD') : null,
        statues: this.searchForm.status,
        // page: this.searchForm.page,
        // page_size: this.searchForm.page_size,
      };
      if (form.status_date_before) {
        form.status_date_before = moment(form.status_date_before).add(1, 'days').format('YYYY-MM-DD');
      }

      this.loading = true;
      // salesReportStatistics(form).then(resp => {
      //   this.total = resp;
      // })

      productionReportDetialsList(form).then(resp => {
        this.items = resp.results;
        this.resetSummary()
        this.calculateSummary()
        this.pagination.total = this.items.length;
        this.setPageData(1);  // 设置初始页数据
      }).finally(() => {
        this.loading = false;
      });
    },

    setPageData(page) {
      const pageSize = 16;  // 你可以将这个设置为一个响应式的数据属性
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      this.currentPageItems = this.items.slice(startIndex, endIndex);
      this.pagination.current = page;
    },

    tableChange(pagination, filters, sorter) {
      this.sortKey = sorter.field;
      this.sortOrder = sorter.order || 'asc'; // 'ascend' 或 'descend'，这里假设 Ant Design Vue 的排序标记
      this.sortData();
      this.setPageData(pagination.current);
    },

    sortData() {
    if (!this.sortKey) return; // 如果没有指定排序字段，则不执行排序

    this.items.sort((a, b) => {
      const isAsc = this.sortOrder === 'ascend';
      if (a[this.sortKey] < b[this.sortKey]) return isAsc ? -1 : 1;
      if (a[this.sortKey] > b[this.sortKey]) return isAsc ? 1 : -1;
      return 0;
    });
  },

    search() {
      this.list();
    },
   
    changeRadio(evt) {
      let value = evt.target.value;
      if (value < 0) {
        this.searchForm.dateRange = [moment().startOf('day').add(value, 'day'), moment().startOf('day')]
      } else {
        this.searchForm.dateRange = [moment().startOf('day').subtract(value, 'day'), moment().startOf('day').subtract(value, 'day'),]
      }
      this.search();
    },
    changeDate() {
      this.radioValue = null;
      this.search();
    },
    changeCategory() {
      this.search();
    },
    resetForm() {
      this.radioValue = '0';
      this.searchForm = {
        dateRange: [moment().startOf('day'), moment().startOf('day')],
        category: null,
        page: 1,
        page_size: 16
      };
    },
  },
  mounted() {
    const { start_date, end_date } = this.$route.query;
    console.log( this.$route.query);
    console.log(start_date, end_date);
    if (start_date && end_date) {
      this.searchForm.dateRange = [moment(start_date), moment(end_date)];
      this.searchForm.status = "Shipped"
    }
    this.initailize();
  },
}
</script>

<style scoped>
.total {
  color: #a94442;
}
</style>